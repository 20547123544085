@font-face {
    font-family: Montserrat-Medium;
    src: url('../../../assets/fonts/Montserrat-Medium_0.ttf') format("truetype")
}

@font-face {
    font-family: Montserrat-MediumItalic;
    src: url('../../../assets/fonts/Montserrat-MediumItalic_0.ttf') format("truetype")
}

@font-face {
    font-family: Montserrat-SemiBold;
    src: url('../../../assets/fonts/Montserrat-SemiBold_0.ttf') format("truetype")
}

@font-face {
    font-family: Montserrat-SemiBoldItalic;
    src: url('../../../assets/fonts/Montserrat-SemiBoldItalic_0.ttf') format("truetype")
}

* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
}

.job_apply_form {
    /* background-color: rgb(242, 195, 133); */
    background-color: #fff0bf;
    padding: 20px;
    width: 100%;
}

.job_apply_form .top_brand_log .brand_img_size {
    width: 200px;
    height: 100px;
}

.job_apply_form .top_brand_log .brand_img_size img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.job_apply_form .top_content_acw {
    background-color: #fff;
    padding: 40px 25px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

}

.job_apply_form .top_content_acw .title {
    color: #be7b08;
    margin-bottom: 12px;
    font-weight: 500;
}

.job_apply_form .top_content_acw .phara {
    font-size: 16px;
    margin-bottom: 0px;
}

.job_apply_form .job_serch_form {
    background: #be7b08;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 0px 10px;
}

.job_apply_form .job_serch_form .form_title {
    color: #fff;
    padding: 25px 20px;
    margin-bottom: 0px;
}

.job_apply_form .job_serch_form Form {
    padding: 0px 10px 10px 10px;
}

.job_apply_form .job_serch_form Form .form-select {
    border-color: 1px solid #ced4da !important;
    height: 45px;
    margin-right: 10px;
}

.job_apply_form .job_serch_form Form .form-select:focus {
    box-shadow: none !important;
    border-color: none !important;
}

.job_apply_form .job_serch_form Form .form-select:focus {
    border-color: #ced4da !important;
    outline: 0;
}

.job_apply_form .job_serch_form Form .form-select:focus-visible {
    outline: none;
    border-color: 1px solid #ced4da !important;
}

.job_apply_form .job_serch_form Form .s_button {
    width: 100%;
}

.job_apply_form .job_serch_form Form .s_button button {
    width: 100%;
    height: 45px;
    border: none;
    color: #be7b08;
    background: #fff;
    outline: none;
    border-radius: 5px;
    letter-spacing: 0.5px;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
}

/* ============ */
.job_apply_form .apply_sec {
    background: #fff;
    border-radius: 5px;
    padding: 25px;
    margin-top: 10px;
}

.job_apply_form .apply_sec .title_loc {}

.job_apply_form .apply_sec .title_loc h4 {
    font-size: 21px;
    margin-bottom: 10px;
    margin-bottom: 5px;
}

.job_apply_form .apply_sec .title_loc p {
    font-size: 16px;
}

.job_apply_form .apply_sec .title_loc p .loc_i {
    position: relative;
    top: -2px;
    margin-right: 5px;
    color: rgb(151, 105, 46);

}

.job_apply_form .apply_sec .cnpd .cn {
    display: flex;
    justify-content: end;
    font-size: 16px;
}

.job_apply_form .apply_sec .cnpd .cn .name {
    color: #000;
    font-weight: 500;
    margin-left: 10px;
}

.job_apply_form .apply_sec .cnpd .np {
    display: flex;
    justify-content: end;
    font-size: 16px;
}

.job_apply_form .apply_sec .cnpd .np .pd {}

.job_apply_form .apply_sec .cnpd .np .dmy {
    margin-left: 10px;
}

.job_apply_form .apply_sec .cpinfo p {
    font-size: 16px;
    margin-bottom: 20px;
}

.job_apply_form .apply_sec .cpinfo a {
    text-decoration: none;
    color: #be7b08;
}

.job_apply_form .apply_sec .company_logo .logo_size {
    width: 100px;
    height: 100px;
    margin: 0 auto;
}

.job_apply_form .apply_sec .company_logo .logo_size img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.job_apply_form .apply_sec .company_logo .apply_btn {}

.job_apply_form .apply_sec .company_logo .apply_btn button {
    width: 100%;
    height: 45px;
    background: #fff;
    color: #be7b08;
    border-color: #be7b08;
    border-radius: 30px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    outline: none;
    font-weight: 500;
    margin-top: 20px;
}

.job_apply_form .apply_sec .company_logo .apply_btn button:focus {
    outline: none;
    box-shadow: none;
}

.job_apply_form .bd_design {
    width: 100%;
    height: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #be7b08;
}

.bottom_brand_log {
    margin-top: 40px;
}

.bottom_brand_log .brand_img_size {
    width: 158px;
    height: 80px;
    margin: 0 auto;
}

.bottom_brand_log .brand_img_size img {
    width: 100%;
    height: 100%;
}

/* =================================== */
.c_details_resume {
    background: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 25px;
    margin-top: 10px;
}

.c_details_resume .title_loc h4 {
    color: #be7b08;
    margin-bottom: 20px;
}

.c_details_resume .title_loc .cn .name {
    color: #000;
    font-weight: 500;
    margin-left: 10px;
}

.c_details_resume .title_loc .np .loc_i {
    position: relative;
    top: -2px;
    margin-right: 5px;
    color: #be7b08;
}

.c_details_resume .title_loc .np .dmy {
    margin-left: 10px;
}

.c_details_resume .title_loc .np .pd {
    margin-left: 10px;
}

.resume_upp {
    text-align: center;
    background-color: #fff0bf;
    padding: 87px 30px;
    border-radius: 5px;
}

.resume_upp h5 {
    color: #be7b08;
    font-weight: bold;
    margin-bottom: 25px;
    font-size: 27px;
}

.resume_upp .choose {
    padding: 8px 20px;
    border-radius: 20px;
    background-color: #be7b08;
    color: white;
    border: none;
    margin: 10px;
}

.resume_upp p {
    margin-top: 10px;
    font-size: 16px;
    margin-bottom: 10px;
}

.resume_upp .apply {
    padding: 8px 20px;
    border-radius: 20px;
    background-color: #1a931a;
    color: white;
    border: none;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.resume_upp .choose_file {
    display: flex;
    justify-content: center;
}

.resume_upp .choose_file label {
    padding: 10px;
    background: #be7b08;
    display: table;
    color: #fff;
    border-radius: 30px;
    height: 45px;
    width: 150px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.resume_upp .choose_file input[type="file"] {
    display: none;
}

.c_details_resume .resume_upp .email_s {
    height: 45px;
}

.c_details_resume .resume_upp .form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #ced4da !important;
    outline: 0;
    box-shadow: none;
}

.c_details_resume .logo_link {
    display: flex;
    margin: 20px 0px;
}

.c_details_resume .logo_link .logo_size {
    width: 100px;
    height: 50px;
    margin-right: 10px;
}

.c_details_resume .logo_link .logo_size img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.c_details_resume .logo_link .c_link {
    display: flex;
    align-items: center;
}

.c_details_resume .logo_link .c_link a {
    text-decoration: none;
    color: #be7b08;
}

.job_apply_form .view_all_jobs {
    background: #be7b08;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.job_apply_form .view_all_jobs a {
    text-decoration: none;
    display: inline-block;
    padding: 10px 25px;
    color: #fff;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.job_apply_form .view_all_jobs a .plus_i {
    margin-right: 5px;
    font-weight: 900;
    font-size: 22px;
}



/* =========review_sec =============== */
.review_sec {
    background-color: #e9f3fe;
    width: 100%;
    height: 720px;
}

.review_sec .icemote {
    width: 500px;
    margin: 0 auto;
    padding: 100px 0px;
}

.review_sec .icemote .img_size {
    width: 100%;
    height: 100px;
    margin-bottom: 20px;
    border-radius: 5px;
}

.review_sec .icemote .img_size img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 5px;
}

.review_sec .content {
    text-align: center;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px 0px;
}

.review_sec .content .ques {
    font-size: 27px;
    margin: 0px;
    color: #be7b08;
}

.review_sec .content .icons_sm {
    margin: 35px 0px;
}

.review_sec .content .icons_sm .smile {
    color: green;
    font-size: 45px;
    margin-right: 20px;
}

.review_sec .content .icons_sm .sad {
    color: red;
    font-size: 45px;
}

.review_sec .content .help_t,
.review_sec .content .add,
.review_sec .content .call {
    font-size: 18px;
}

/* ===== review_prosec======= */
.review_pro_sec {
    background-color: #e9f3fe;
    width: 100%;
    height: 720px;
}

.review_pro_sec .iprotex {
    width: 650px;
    margin: 0 auto;
    padding: 100px 0px;
}

.review_pro_sec .iprotex .img_size {
    width: 500px;
    height: 100px;
    border-radius: 5px;
    margin: 0 auto 20px;
}

.review_pro_sec .iprotex .img_size img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.review_pro_sec .iprotex .content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
}

.review_pro_sec .iprotex .content .icons_smile .smile {
    color: green;
    font-size: 65px;
    margin-bottom: 15px;
}

.review_pro_sec .iprotex .content .tq_tx {
    font-size: 20px;
    margin-bottom: 10px;
}

.review_pro_sec .iprotex .content .progress {
    height: 1.7rem;
    border-radius: 0.5rem;
}

.review_pro_sec .iprotex .content .progress-bar {
    background-color: #1a931a;
}

.review_pro_sec .iprotex .content .percent_text {
    font-size: 20px;
}

.review_pro_sec .iprotex .content .complete_login {
    margin-top: 35px;
}

.review_pro_sec .iprotex .content .complete_login button {
    height: 50px;
    width: 100%;
}

.review_pro_sec .iprotex .content .complete_login button:focus {
    box-shadow: none;
}

.review_pro_sec .iprotex .content .complete_login .btn_1 {
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #fff;
    background: #1a931a;
    border-radius: 5px;
}

.review_pro_sec .iprotex .content .complete_login .btn_2 {
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #000;
    background: #f5f5f5;
    border-radius: 5px;
}

.review_pro_sec .iprotex .content .complete_login .tr {
    font-size: 32px;
}



/* ====== feedback ========== */
.feedback .iprotex .content .sad {
    color: red;
    font-size: 65px;
    margin-bottom: 15px;
}

.feedback .iprotex .content .ques {
    font-size: 27px;
    color: #1c3078;
}

.feeds_widget li:hover {
    background-color: #1c3078;
    color: white !important;
}

#page_top:hover {
    color: white !important;
}

#page_top .page-header .left .css-b62m3t-container {
    color: #000 !important;
}

.notification .dropdown {
    color: #1c3078 !important;
}

.feedback .iprotex .content .feed_t {
    font-size: 15px;
    margin-bottom: 25px;
}

.feedback .feed_area .form-control:focus {
    color: #212529 !important;
    background-color: #fff;
    border-color: #ced4da !important;
    outline: 0 !important;
    box-shadow: none;
}

.feedback .submit_btn button {
    background-color: #1c3078 !important;
    color: #fff;
    letter-spacing: 0.5px;
    font-weight: 500;
    text-transform: capitalize;
    width: auto;
    height: 45px;
    font-family: Montserrat-SemiBold !important;
    font-size: 15px !important;
    border-radius: 6px !important;
}

.feedback .submit_btn button:focus {
    box-shadow: none;
}

.common_bc1 {
    height: 45px;
    font-weight: 500;
    background-color: #1c3078;
    color: #fff;
    width: 100px;
    margin-top: 20px;
    font-family: Montserrat-SemiBold !important;
    font-size: 15px !important;
    border-radius: 6px !important;
}

.common_bc {
    height: 45px;
    font-weight: 500;
    background-color: #1c3078;
    color: #fff;
    width: 100px;
    margin-top: 20px;
    font-family: Montserrat-SemiBold !important;
    font-size: 15px !important;
    border-radius: 6px !important;
}

.common_bc:hover,
.common_bc1:hover {
    color: #fff;
}

/* ===media quiry====== */
@media only screen and (max-width: 600px) {

    .feilds-1,
    .feilds {
        margin-bottom: 10px;
    }

    .job_apply_form .apply_sec .cpinfo {
        margin-bottom: 20px;
    }



    .job_apply_form .apply_sec .cnpd {
        margin-bottom: 15px;
    }

    .job_apply_form .apply_sec .cnpd .cn {
        display: flex;
        justify-content: flex-start;
        font-size: 14px;
    }

    .job_apply_form .apply_sec .cnpd .np {
        display: flex;
        justify-content: flex-start;
        font-size: 14px;
    }

    .review_pro_sec .iprotex {
        width: 350px;
        margin: 0 auto;
    }

    .review_pro_sec .iprotex .img_size {
        width: 300px;
        margin: 0 auto 20px;
    }

    .feedback .iprotex .content .ques {
        font-size: 24px;
    }

    .review_pro_sec .iprotex .content .complete_login button {
        margin-bottom: 15px;
    }

    .review_sec .icemote {
        width: 350px;
        margin: 0 auto;
    }

    .review_sec .content .ques {
        font-size: 22px;
    }
}

@media only screen and (min-width: 600px) {
    .job_apply_form .apply_sec .cnpd .cn {
        font-size: 13px;
    }
}

@media only screen and (min-width: 768px) {

    .feilds-1,
    .feilds {
        margin-bottom: 10px;
    }

    .job_apply_form .apply_sec .cpinfo {
        margin-bottom: 20px;
    }

    .job_apply_form .apply_sec .cnpd .cn {
        display: flex;
        justify-content: flex-start;
        font-size: 16px;
    }

    .job_apply_form .apply_sec .cnpd .np {
        display: flex;
        justify-content: flex-start;
        font-size: 16px;
    }

    .job_apply_form .apply_sec .cnpd {
        margin-bottom: 15px;
    }

    .review_pro_sec .iprotex .content .complete_login button {
        margin-bottom: 15px;
    }
}

.dial_num {
    font-weight: 500;
    color: #1c3078;
    text-decoration: underline;
    cursor: pointer;
}