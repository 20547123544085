@import "./assets/plugins/bootstrap/css/bootstrap.min.css";

@import "./assets/css/main.css";

@import "./assets/css/theme2.css";

@import "./assets/css/custom.css";

@import "./assets/plugins/charts-c3/c3.min.css";

@import "./assets/plugins/summernote/dist/summernote.css";

@import "./assets/plugins/jvectormap/jvectormap-2.0.3.css";

@import "./assets/plugins/bootstrap-multiselect/bootstrap-multiselect.css";

@import "./assets/plugins/bootstrap-datepicker/css/bootstrap-datepicker3.min.css";

@import "./assets/plugins/fullcalendar/fullcalendar.min.css";

@import "./assets/plugins/jquery-steps/jquery.steps.css";

@import "./assets/css/pmsstyle.css";

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

#generate {
  padding: 1px;
  margin-top: 23px;
  margin-right: 20px;
  width: 165px !important;
}

.right-allign-menu {
  text-align: right;
}

/* .ul-class a {}

.ag-header-cell-label {
    justify-content: center;
}

.modal_images_design {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 230px;
    height: 240px;
    padding: 10px;
    margin: 10px 0px;
}

.roomtype-image-div-M0odal {
    margin: auto;
}

.menu_imagews_drag {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 13px 0px;
}

.roomtype-image-div-M0odal img {
    height: 180px !important;
    width: auto;
} */