/* -----------------common css------------------ */
/* --------------canvas start----------- */
.offcanvas_header_title {
    font-size: 18px !important;
    color: #1c3078 !important;
    font-family: Montserrat-SemiBold !important;
}

/* --------------canvas end----------- */
/* --------------Apex Chart start----------- */
.apexcharts-legend-text {
    font-size: 15px !important;
    color: #272727 !important;
    font-family: Montserrat-Medium !important;
}

.ticket_priority_count_dashboard .apexcharts-legend {
    right: 26px !important;
}

.apexcharts-tooltip {
    font-size: 15px !important;
    color: #272727 !important;
    font-family: Montserrat-Medium !important;
}

.ticket_reports .apexcharts-tooltip {
    font-size: 15px !important;
    color: #272727 !important;
    font-family: Montserrat-Medium !important;
}

/* --------------Apex Chart end----------- */
/* --------------Error Validation Message start----------- */
.invalid-feedback {
    font-family: Montserrat-Medium !important;
}

/* --------------toggle button--start----- */
.custom-control-label {
    font-family: Montserrat-Medium !important;
}

.custom-switch-description {
    font-family: Montserrat-Medium !important;
    color: #272727 !important;
    font-size: 15px !important;
}

.custom-switch-description {
    font-family: Montserrat-Medium !important;
    color: #272727 !important;
    font-size: 15px !important;
}

/* --------------toggle button--end----- */
h6 {
    font-size: 16px !important;
    font-family: Montserrat-SemiBold;
    color: #1c3078;
}

p {
    font-size: 15px !important;
    font-family: Montserrat-Medium;
    color: #272727;
}

.form-control::placeholder {
    color: #a99d9d !important;
}

.label_black {
    font-size: 15px !important;
    color: #272727 !important;
    font-family: Montserrat-SemiBold !important;
}

/* --------------no records to show-start---- */
.no_records h5 {
    font-size: 18px !important;
    font-family: Montserrat-Medium;
    color: #272727;
}

/* --------------no records to show-end---- */

/* -------------guest-start---- */
.list_guest_page p {
    font-size: 13px !important;
    font-family: Montserrat-Medium;
    color: white;
}

.list_guest_page .view_hotel_card p {
    font-size: 15px !important;
    font-family: Montserrat-Medium;
    color: #272727;
}

/* -------------guest-start-end--- */
/* reviews----------------- */
.socail_reviews {
    font-size: 18px !important;
    color: #1c3078 !important;
    font-family: Montserrat-SemiBold !important;
}

.socail_reviews_body .text {
    font-family: Montserrat-Medium !important;
    color: #272727 !important;
    font-size: 15px !important;
}












.nav-rev {
    margin: 0 10px 0 10px;
}

.title-left {

    margin-left: 6px;
}

.res-id {
    margin: 8px 0 0 0;
}

.icon-printer {
    margin: 13px 0px 0px 0px;
    cursor: pointer;
}

.nav-dot {
    font-size: 23px;
    rotate: 90deg;
    color: black;
    width: 10px !important;
    /* padding: 2px; */
    margin-top: 0;
    margin-left: 12px;
}

.settle-list {
    width: 420px !important;
}

.settle-list label:hover {
    background-color: transparent !important;
    color: #333537 !important;
}

.title_header {
    justify-content: space-between;
}



/* .down-arrow{
    position: relative;
} */
.arrow-lable {
    font-size: 15px;
    position: absolute;
    right: 20px;
    bottom: 15px;
    cursor: pointer;
}

/* .card-footer {
    display: flex;
    align-items: center;
    justify-content: space-between !important;
    margin-top: 43px;
} */

.card-footer .btn-Settle {
    color: #FEFEFE;
    height: 45px;
    border-radius: 11px;
    padding: 0px 12px;
    font-size: 16px;
    font-family: Montserrat-SemiBold !important;
}

.card-footer .btn-Check {
    color: #FEFEFE;
    padding: 3px;
    border-radius: 11px;
}

.card-right {
    rotate: 90deg;
    font-size: 20px;
}

.card-body .footer-add {
    position: absolute;
    bottom: 20px;
    font-size: 25px;
    right: 20px;
    color: #1c3078;
}

.footer-group .settle-list {
    width: 240px !important;
}

.viewreservation_card {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}

.viewreservation_card .card-title-left {
    background-color: green;
    padding: 8px;
    border-radius: 10px;
    color: #fff;
}

.viewreservation_print {
    text-align: center;
}

.add_icon_viewreservation {
    display: flex;
    justify-content: end !important;
    align-items: center !important;
}

.add_icon_viewreservation i {
    font-size: 45px !important;
    color: #1c3078 !important;
    cursor: pointer;
}

.viewreservation_guest {
    display: flex !important;
    justify-content: space-between !important;
}

.card-body_reservation {
    /* padding: 0px 0px !important; */
    flex: 1 1 auto;
    position: relative;
}

.reservation_card_height {
    height: 360px !important;
    z-index: 1 !important;
}

.payer_expanded {
    z-index: 2 !important;
    /* Adjust the value as needed */
}

.reservation_display_align .reservation_label {
    width: 170px;
    white-space: nowrap;
    overflow: visible;
    text-overflow: ellipsis;
    text-align: start;
    font-size: 15px;
    /* font-weight: 600; */
    color: #1c3078;
    font-family: Montserrat-SemiBold !important;
}


.reservation_display_align .reservation_h4 {
    width: 170px;
    white-space: nowrap;
    overflow: visible;
    text-overflow: ellipsis;
    text-align: end;
    font-size: 14px;
    text-wrap: pretty;
}

.rounded_icon img {
    width: 50%;
    height: 50%;
}

.add_icon_reservation_view {
    /* position: absolute; */
    display: flex;
    bottom: 17px;
    justify-content: end !important;
    align-items: center !important;
}

.add_icon_reservation_view i {
    font-size: 25px !important;
    color: #1c3078 !important;
    cursor: pointer;
}

.card-footer,
.card-bottom {
    padding: 10px !important;
}

.view_reservation .card-footer {
    height: 70px !important;
    display: flex;
    justify-content: end;
    align-items: center;
}

.view_reservation_guest_maindiv {
    position: absolute;
}

.reserve_detailss .card {
    padding: 10px 10px 10px !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.reserve_detailss .card .card-header {
    border-bottom: 1px solid #cccccc;
    padding: 5px 0px 10px 0px !important;
}

.reserve_detailss .card .card-body {
    padding: 0px !important;
}

.reserve_detailss .card .card-body .reserve_details {
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    padding: 10px;
    background: linear-gradient(to top, #e9f3fe 0%, #fff 35%) !important;
}

.reserve_detailss .card .card-body .reserve_details h4 {
    font-size: 16px !important;
    color: #1c3078 !important;
    font-family: Montserrat-SemiBold !important;
}

.reserve_detailss .card .card-body .reserve_details p {
    font-size: 14px !important;
    color: #272727 !important;
    font-family: Montserrat-Medium !important;
    margin-bottom: 0px;
}

.reserve_detailss .card-view-pms .card-header {
    border-bottom: 1px solid #cccccc;
    padding: 5px 0px 10px 0px !important;
}

.reserve_detailss .card-view-pms .card-body {
    padding: 0px !important;
}

.reserve_detailss .card-view-pms .card-body .reserve_details {
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    padding: 10px;
    background: linear-gradient(to top, #e9f3fe 0%, #fff 35%) !important;
}

.reserve_detailss .card-view-pms .card-body .reserve_details h4 {
    font-size: 16px !important;
    color: #1c3078 !important;
    font-family: Montserrat-SemiBold !important;
}

.reserve_detailss .card-view-pms .card-body .reserve_details p {
    font-size: 14px !important;
    color: #272727 !important;
    font-family: Montserrat-Medium !important;
    margin-bottom: 0px;
}

.viewreservation_maindiv {
    /* position: relative; */
    padding-top: 10px;
}

.card-body_reservation .text-col-blue span {
    font-size: 18px;
}

.card-body_reservation .view_reservation_folio {
    display: flex !important;
    justify-content: start !important;
    align-items: center !important;
}

.card-body_reservation .view_reservation_payer {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding-top: 29px;
}

.floating_button_reservation {
    z-index: 999;
    right: 0px !important;
    top: 0px !important;
}

.floating_button_reservation .icons_plus {
    background-color: #D7EAFF !important;
    border: 1px solid #1c3078;
}

.fab-container {
    bottom: 10vh;
    position: fixed;
    margin: 0px !important;
}

.floating_button_reservation i {
    color: #1c3078;
}

.floating_button_reservation .add_button {
    color: white !important;
}

.floating_button_reservation:hover .fab-icons {
    display: flex;
    opacity: 1;
    transform: translateY(-10px);
}

.reservation_checkin_room .print_guest_pilio {
    text-align: center;
}

.reservation_checkin_room .label_checkin_room {
    font-size: 20px;
    font-weight: bolder;
}

.reservation_checkin_room .label_checkin_print {
    font-size: 15px;
    font-weight: bolder;
    padding: 10px;
    border: 2px solid;
    background-color: #d7eaff;
    box-shadow: 2px 2px 2px 0px;
    cursor: pointer;
}

.reservation_early_checkin .box {
    border: 2px solid;
}

.add_reservation .btn-secondary_reservation {
    width: 100%;
    font-size: 14px !important;
    font-family: Montserrat-Medium !important;
    border-radius: 10px !important;
    border: 1px solid #6e6e6e;
    color: #1c3078 !important;
    background-color: #d3d3d3;
    height: 100px !important;
    padding: 0px;
}

.booking_info .btn-secondary_reservation {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    font-size: 14px !important;
    font-family: Montserrat-Medium !important;
    border-radius: 10px !important;
    border: 1px solid #6e6e6e;
    color: #1c3078 !important;
    /* background-color: linear-gradient(to top, #e9f3fe 0%, #fff 35%) !important; */
    background: #e9f3fe !important;
    height: 90px !important;
    box-shadow: 2px 2px 2px 0px;
}

.booking_info .btn-secondary_reservation {
    position: relative;
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.booking_info .btn-secondary_reservation:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transform: translate3d(3px, 3px, 3px);
}

.btn-secondary_reservation_button {
    width: 90%;
    font-size: 18px !important;
    font-family: Montserrat-Medium !important;
    border-radius: 4px !important;
    border: 1px solid #6e6e6e;
    color: #fff !important;
    background-color: #1c3078;
    height: 40px !important;
}

.btn-secondary_reservation:hover {
    background-color: #0000000a;
}

.reservation_body {
    border: 2px solid #ccc;
    padding: 20px;
    border-right: 0px !important;
}

.reservation_body_two {
    border: 2px solid #ccc;
    padding: 20px;
}

.reservation_body .rightborder {
    border-right: 2px solid;
}

.reservation_row .night-counter {
    text-align: center;
}

.reservation_rate {
    margin-left: 50px !important;
    margin-top: -5px !important;
}

.edit_reservation {
    cursor: pointer;
}

.card_reservation_header {
    font-weight: 700;
    color: #1c3078;
}

.offcanvas_header {
    background-color: #e9f3fe;
}

.offcanvas_header_title {
    display: flex;
    align-items: center;
    color: #1c3078;
    margin-bottom: 0px !important
}

.reservation_canvas_footer_button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #e9f3fe;
}

.reservation_attachments {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.reservation_img img {
    width: 100%;
    height: 95px !important;
}

.upload_reservation .reservation_icon {
    position: absolute !important;
    top: 1px !important;
    right: 8px !important;
    cursor: pointer !important;
    border-bottom-left-radius: 10px;
    background-color: red;
    padding: 5px;
    color: #fff;
    border-top-right-radius: 10px;
}

.offcanvas_width_changes .polio_guest {
    font-size: 22px;
    position: relative;
    bottom: 2px;
}

.close-reservation-icon i {
    padding: 6px;
    background: red;
    border-radius: 20px;
    color: white;
    font-size: 10px;
    cursor: pointer;
}

.cursor_pointer_reservation input,
.cursor_pointer_reservation label {
    cursor: pointer !important;
}

.underline_text_reservation {
    text-decoration: underline;
    font-size: 15px;
    color: #1c3078 !important;
}

.card-body_reservation hr {
    height: 1px;
    position: relative;
    right: 12px;
    color: #ccc;
    margin-top: 10px;
}

.view_reservation .reservation_price_history tbody,
.view_reservation .reservation_price_history th {
    background: linear-gradient(to top, #e9f3fe 0%, #fff 35%) !important;
    height: 50px !important;
    border-bottom: 1.1px solid #ccc;
    padding: 10px;
    color: #1c3078 !important;
}

.view_reservation .Over_all_table_style tbody,
.view_reservation .Over_all_table_style td {
    background: linear-gradient(to top, #e9f3fe 0%, #fff 35%) !important;
    height: 45px !important;
    border-bottom: 1.1px solid #ccc;
    padding: 10px;
    font-size: 14px;
    color: #000;
}

.view_reservation .reservation_status {
    width: auto;
    color: #272727;
    cursor: pointer;
    height: 30px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    padding: 0px 22px;
    font-family: Montserrat-SemiBold !important;
}

.view_reservation .view-reservation-name {
    font-weight: 600;
}

.view_reservation .view-reservation-category {
    font-weight: 500;
}

.book_details_reservation .body_label {
    font-weight: 400;
    display: block;
    margin-bottom: 0.375rem;
    font-size: 14px;
}

.book_details_reservation .header_label {
    font-weight: 600;
    display: block;
    margin-bottom: 0.375rem;
    font-size: 17px;
}


/* magiclink */

.form-group .email_lines {
    margin-left: 67px;
    font-size: 14px;
}

.form-group .magic_link {
    font-size: 14px;

}

.side_arrow_font_desk .arow_date {
    padding: 10px;
    background-color: #d7eaff;
    border-radius: 20px;
    font-size: 16px;
    color: #1c3078;
    cursor: pointer;
}

.filter_front_desk {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px;
}

.filter_front_desk .rmdp-input {
    padding: 0px 13px;
}

.filter_front_desk .side_arrow_font_desk {
    z-index: 99;
}

.filter_front_desk .side_arrow_font_desk .selectgroup-item {
    margin: 0px;
}

.filter_front_desk .side_arrow_font_desk .selectgroup {
    align-items: center;
}

.move_room_management .warning_move_room {
    display: flex;
    align-items: center;
}

.move_room_management .warning_move_room i {
    font-size: 25px;
    color: red;
    padding-right: 15px;
}

.settle_due_pages {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
}

.settle_due_pages .warning_move_room {
    display: flex;
    align-items: center;
}

.settle_due_pages .warning_move_room i {
    font-size: 25px;
    color: red;
    padding-right: 15px;
}

.booking_info img {
    height: 28px;
    margin-top: 8px;
}

.booking_info .reservation_label {
    margin-top: 4px;
}

.viewreservation_maindiv .checkoutview_reservation .accordion-button {
    /* color: #d3d3d3 !important; */
    background-color: #d3d3d3 !important;
    font-weight: 600 !important;
    border: 2px solid;
}

.viewreservation_maindiv .checkoutview_reservation .accordion-button::after {
    color: #1c3078;
}

.view_reservation_folio .checkout_button {
    background-color: #1c3078;
    color: #fff;
}

.checkout_reservation .box_checkout_reservation {
    border: 2px solid;
    padding: 20px
}

.view_reservation_checkout .accordion-button {
    font-size: 18px;
    font-weight: 600 !important;
}

/* magiclink */

.form-group .email_lines {
    margin-left: 67px;
    font-size: 14px;
}

.form-group .magic_link {
    font-size: 14px;

}

.phone_lines {
    margin-left: 40px;
    font-size: 14px;
}

.amendstay_maindiv .amendstay {
    border-bottom: 2px solid;
}

.amendstay_maindiv .amendstay .reservation_label {
    text-align: center;
    font-weight: 700;
    color: #1c3078;
}

.amendstay_maindiv .amendstay .actual_nights {
    font-size: 20px;
    font-weight: bold;
    border: 2px solid #868686;
    border-radius: 12px;
    padding: 7px;
    padding-left: 15px;
}

.amendstay_maindiv .amendstay .head {
    text-align: center;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}


.view_hotel_card {
    border-radius: 10px !important;
    background: #e9f3fe;
    padding: 20px !important;
}

.view_hotel_sec .card-header {
    background-color: #e9f3fe !important;
    border-radius: 10px 10px 0px 0px;
    height: 70px;
    font-size: 18px;
    font-weight: bold;
}

/* .amendstay_maindiv {
    padding: 10px;
} */

.amendstay_maindiv .save_button {
    margin-top: 80px;
}

.date_settle_dues .rmdp-container {
    display: block !important;
}

.add_reservation_header .add_reservation_option {
    padding: 10px;
    cursor: pointer !important;
}

.add_reservation_header .add_reservation_option:hover {
    background-color: #1c3078 !important;
    font-weight: 500;
    color: #fff !important;
}

.add_reservation_header .add_reservation_option:hover .add_reservation_option_icon,
.add_reservation_header .add_reservation_option:hover .form-label {
    color: #fff !important;
}

.add_reservation_header .add_reservation_option {
    display: flex;
    align-items: center;
}

.add_reservation_header .add_reservation_option i {
    font-size: 18px;
    margin-right: 10px;
    cursor: pointer !important;
}


.add_reservation_header .add_reservation_option label {
    margin: 0px;
    cursor: pointer !important;
}

.add_reservation_header {
    padding: 10px;
}

.canvas_footer_button_reservstion {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.filter_front_desk .side_arrow_font_desk .button_filter {
    border: 1px solid #6e6e6e;
    color: #1c3078 !important;
    background: linear-gradient(to top, #e9f3fe 0%, #fff 35%);
    padding: 7px 20px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 600;
}

/* --------------------------------------------reservation dashboard start---- */
.col-1\.5 {
    flex: 0 0 12.5%;
    /* 12.5% width, no shrinking, no growing */
    max-width: 12.5%;
}

@media (min-width: 768px) {
    .col-md-1\.5 {
        flex: 0 0 12.5%;
        /* 12.5% width, no shrinking, no growing */
        max-width: 12.5%;
    }
}

@media (min-width: 1200px) {
    .col-xl-1\.5 {
        flex: 0 0 12.5%;
        /* 12.5% width, no shrinking, no growing */
        max-width: 12.5%;
    }
}

.reservation_list .dash_text_reservation {
    /* color: rgb(68, 68, 68); */
    font-size: 16px;
    margin-top: 6px;
    color: #272727 !important;
    font-family: Montserrat-SemiBold !important;
}

.reservation_list .card {
    height: 120px !important;
    width: auto;
}

.reservation_list .row>* {
    padding-left: 0px !important;
}

.reservation_list .dash_img img {
    width: 100% !important;
    height: 100% !important;
}

.reservation_list .my_sort_cut .dash_img {
    width: 36px;
    height: 36px;
    display: inline-block;
    margin-top: 13px;
    padding: 2px;
}

.reservation_list .ribbon .ribbon-box_reservation {
    color: #1c3078;
    background-color: white;
    right: auto;
    left: 0;
    top: 8px;
    position: absolute;
    height: 25px;
    min-width: 30px;
    padding: 0 5px;
    text-align: center;
    line-height: 25px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    font-weight: 700;
}

/* --------------------------------------------reservation dashboard end---- */
.view_reservation .timeline_item .header_label,
.view_reservation .table-responsive .form-label {
    font-size: 15px;
    color: #1c3078;
}

.view_reservation .timeline_item .body_label,
.view_reservation .table-responsive .col-md-7 label {
    font-size: 14px;
    color: #000;
}

.plus_minus i {
    font-size: 25px !important;
}

.card-body-reservation {
    padding: 0px 10px 10px 10px;
}

.view_hotel_card .col-sm-5,
.view_hotel_card .col-sm-2 {
    text-align: left;
}

.view_hotel_card .col-sm-5 label {
    font-size: 15px !important;
    font-family: Montserrat-Medium !important;
    color: #272727 !important;
}

.reservation_card_height .accordion-header button {
    justify-content: space-between;
    background-color: #E9F3FE !important;
    width: 100% !important;
}

.reservation_card_height .accordion-header {
    background-color: #E9F3FE !important;
}

.viewreservation_maindiv p {
    margin-bottom: 0px;
}