/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {}



@media only screen and (min-width: 1024px) {}

/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-width: 992px) {}



/* Surface pro 7  */

@media only screen and (max-device-width: 912px) {
    .page-header {
        display: flex;
    }

    .modal.show .modal-dialog {
        max-width: 70% !important;
        margin-top: 70px;
    }
}

/* ipad air  */

@media only screen and (max-device-width: 820px) {
    .page-header {
        display: flex;
    }

    .modal.show .modal-dialog {
        max-width: 70% !important;
        margin-top: 70px;
    }

    .upcommcomp_btn button {
        width: 150px;
    }

    .userView .col-lg-5 {
        border: none;
    }

    .common_print {
        margin-left: 10px;
    }

    .taskview_ribbon .bor-right {
        border: none;
    }

    .userView .calendarborder {
        border: none;
    }

    .assign {
        margin-top: 15px;
    }

    .price_inc_de {
        justify-content: start;
    }

    .qua_lable {
        margin-left: 0px;
    }

    .message_detail {
        border: none !important;
    }

    .upcommcomp_btn {
        flex-wrap: wrap;
    }

    .upcommcomp_btn button {
        margin-bottom: 15px;
    }
}




/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (max-width: 768px) {
    .section-body {
        margin-left: 0px !important;
    }

    .chat_windows .message .received-time {
        left: -240px;
    }

    .review_sec .reply_icon {
        display: flex;
        margin-left: 10px;
    }

    .chat_windows .message p {
        width: 360px;
    }

    .page-header {
        display: flex;
    }

    .page-header .right {
        margin-top: 0px;
        /* display: block !important; */
    }

    .top_img_details {
        border-right: none !important;
    }

    .modal.show .modal-dialog {
        max-width: 70% !important;
        margin-top: 70px;
    }

    .upcommcomp_btn button {
        width: 150px;
    }

    .vp_card_option {
        margin-top: 0px;
        justify-content: space-around;
    }

    .taskview_ribbon .bor-right {
        border: none;
    }

    .userView .calendarborder {
        border: none;
    }

    .assign {
        margin-top: 15px;
    }

    .price_inc_de {
        justify-content: start;
    }

    .qua_lable {
        margin-left: 0px;
    }

    .message_detail {
        border: none !important;
    }

    .upcommcomp_btn {
        flex-wrap: wrap;
    }

    .upcommcomp_btn button {
        margin-bottom: 15px;
    }
}




/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 600px) {}




/* /* ---- iphone 13pro max ---------- */

@media only screen and (max-device-width: 430px) {
    .section-body {
        margin-left: 0px !important;
    }

    .review_sec .csv_main {
        flex-wrap: wrap-reverse !important;
    }

    .review_sec .csv_main .nav-pills {
        margin-top: 10px;
        margin-bottom: 30px;
        margin-left: 0px;
    }

    .footer {
        display: none;
    }

    .review_sec .container-fluid {
        background-color: #e9f3fe;
    }

    .review_sec .bulk_tab li .nav-link {
        font-size: 14px !important;
    }

    .review_sec .guest_row {
        padding-right: 0px;
    }

    .modal.show .modal-dialog {
        max-width: 100% !important;
        margin-top: 70px;
    }

    .chat_windows .message p {
        width: 270px;
    }

    .chat_windows .message .received-time {
        left: -150px;
    }

    .review_sec .reply_icon {
        display: flex;
    }

    .top_title_icon {
        flex-wrap: wrap !important;
    }

    .top_title_icon .card-options {
        margin-left: 30px;
        margin-top: 15px;
    }

    .breadcrump p {
        background-color: #e9f3fe;
    }

    .review_sec .report_topalign {
        flex-wrap: wrap;
    }

    .ag-theme-alpine .ag-paging-panel>* {
        font-size: 14px !important;
    }

    .review_sec .top_title {
        margin-right: 53px !important;
    }

    .chart_collaps button {
        font-size: 15px;
    }

    .common_title_fs {
        font-size: 14px;
    }

    .pagination {
        margin-bottom: 20px !important;
    }

    .chart_collaps .border_right {
        border-right: 0px;
    }

    .media-body .revie_tit_v {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .page-header {
        flex-wrap: wrap;
        padding: 10px 0;
    }

    .page-header .left {
        margin-bottom: 10px;
    }

    #page_top {
        height: 110px;
    }

    .no_data_display_img {
        width: 225px;
    }

    .tit_res {
        display: block;
    }

    .card-options {
        margin-left: 0px;
        margin-top: 10px;
    }

    .add_card_options {
        margin-left: 20px;
    }

    .num_res {
        margin-top: 0px;
    }

    .set_permission_res {
        display: block;
    }

    .res_tit_tick {
        display: block;
    }

    .card .card-options .dropdown-toggle {
        margin-left: 15px;
    }

    .ticket_export {
        margin-left: 0px !important;
    }

    .view_btn_tic_res {
        margin-top: 10px;
        float: left;
    }

    .next_previous_btn {
        justify-content: space-between;
    }

    .breadcrump p {
        flex-wrap: wrap;
        padding-left: 20px;
    }

    .user_wise_nacat {
        width: 100%;
    }

    .Over_all_table_style {
        padding: 0px;
    }

    .review_sec .csv_main {
        display: block !important;
    }

    .send-review-bread {
        right: 20px;
    }

    .upcommcomp_btn button {
        width: 150px;
        margin-bottom: 10px;
    }

    .upcommcomp_btn {
        flex-wrap: wrap;
    }

    .mandatory {
        display: block;
    }

    .save_update_res {
        justify-content: space-between;
    }

    .save_update_res .form-group {
        margin-left: 40px;
    }

    .title_mt {
        display: block;
    }

    .title_mt .header-action {
        float: left;
        margin-top: 15px;
    }

    .vp_card_option {
        margin-top: 0px;
        justify-content: center;
    }

    .userView .col-lg-5 {
        border: none;
    }

    .border_list_cash_count {
        border: none;
    }

    .cash_count_card button {
        width: 100%;
        margin-bottom: 10px;
    }

    .cash_count_card .card-footer {
        display: grid;
    }

    .common_print_pageg .title_print_btn {
        display: block;
    }

    .print_back {
        display: flex;
        margin-top: 15px;
    }

    .common_print {
        margin-left: 10px;
    }

    .assign {
        margin-top: 15px;
    }

    .price_inc_de {
        justify-content: start;
    }

    .qua_lable {
        margin-left: 0px;
    }

    .message_detail {
        border: none !important;
    }

    .set_permission_res {
        display: block;
    }

    .set_permission_input_res {
        margin-left: 25px;
    }

    .flg_col_res {
        padding-right: 7.5px;
    }

    .num_col_res {
        padding-left: 7.5px;
        margin: 20px 0;
    }

    .header-action .guest_checkinnow {
        padding: 10px 5px;
        font-size: 15px;
    }

    #statusChange .modal-body,
    #disableModal .modal-body {
        width: 410px;
    }
}



/* /* ---- iphone XR max ---------- */

@media only screen and (max-device-width: 414px) {
    .section-body {
        margin-left: 0px !important;
    }

    .footer {
        display: none;
    }

    .review_sec .container-fluid {
        background-color: #e9f3fe;
    }

    .review_sec .bulk_tab li .nav-link {
        font-size: 14px !important;
    }

    .review_sec .guest_row {
        padding-right: 0px;
    }

    .modal.show .modal-dialog {
        max-width: 100% !important;
        margin-top: 70px;
    }

    .page-header {
        flex-wrap: wrap-reverse;
        padding-top: 4px;
    }

    #page_top .page-header .left .css-b62m3t-container {
        width: 388px;
    }

    .tit_res {
        flex-wrap: wrap;
    }

    .card-options {
        margin-left: 0px;
        margin-top: 15px;
    }

    .top_img_details {
        border-right: none !important;
    }

    .hot_img_size {
        width: 100%;
        height: 250px;
    }

    .add_card_options {
        margin-left: 20px !important;
    }

    .num_res {
        margin-top: 0px;
    }

    .set_permission_res {
        display: block;
    }

    .res_tit_tick {
        display: block;
    }

    .card .card-options .dropdown-toggle {
        margin-left: 15px;
    }

    .ticket_export {
        margin-left: 0px !important;
    }

    .view_btn_tic_res {
        margin-top: 10px;
        float: left;
    }

    .next_previous_btn {
        justify-content: space-between;
    }

    .breadcrump p {
        flex-wrap: wrap;
        padding-left: 20px;
    }

    .user_wise_nacat {
        width: 100%;
    }

    .Over_all_table_style {
        padding: 0px;
    }

    .send-review-bread {
        right: 20px;
    }

    .upcommcomp_btn button {
        width: 150px;
        margin-bottom: 10px;
    }

    .upcommcomp_btn {
        flex-wrap: wrap;
    }

    .mandatory {
        display: block;
    }

    .save_update_res {
        justify-content: space-between;
    }

    .save_update_res .form-group {
        margin-left: 40px;
    }

    .title_mt {
        display: block;
    }

    .title_mt .header-action {
        float: left;
        margin-top: 15px;
    }

    .vp_card_option {
        margin-top: 0px;
        justify-content: center;
    }

    .userView .col-lg-5 {
        border: none;
    }

    .border_list_cash_count {
        border: none;
    }

    .cash_count_card button {
        width: 100%;
        margin-bottom: 10px;
    }

    .cash_count_card .card-footer {
        display: grid;
    }

    .common_print_pageg .title_print_btn {
        display: block;
    }

    .print_back {
        display: flex;
        margin-top: 15px;
    }

    .common_print {
        margin-left: 10px;
    }

    .assign {
        margin-top: 15px;
    }

    .price_inc_de {
        justify-content: start;
    }

    .qua_lable {
        margin-left: 0px;
    }

    .message_detail {
        border: none !important;
    }

    .set_permission_res {
        display: block;
    }

    .set_permission_input_res {
        margin-left: 25px;
    }

    .flg_col_res {
        padding-right: 7.5px;
    }

    .num_col_res {
        padding-left: 7.5px;
        margin: 20px 0;
    }

    .header-action .guest_checkinnow {
        padding: 10px 5px;
        font-size: 15px;
    }

    #statusChange .modal-body,
    #disableModal .modal-body {
        width: 410px;
    }
}




/* pixel 5  ----------- */

@media only screen and (max-device-width: 393px) {
    .section-body {
        margin-left: 0px;
    }

    .modal.show .modal-dialog {
        max-width: 100% !important;
        margin-top: 70px;
    }

    .num_res {
        margin-top: 0px;
    }

    .set_permission_res {
        display: block;
    }

    .view_btn_tic_res {
        margin-top: 10px;
        float: left;
    }

    .next_previous_btn {
        justify-content: space-between;
    }

    .breadcrump p {
        flex-wrap: wrap;
        padding-left: 20px;
    }

    .user_wise_nacat {
        width: 100%;
    }

    .Over_all_table_style {
        padding: 0px;
    }

    .send-review-bread {
        right: 20px;
    }

    .upcommcomp_btn button {
        width: 150px;
        margin-bottom: 10px;
    }

    .upcommcomp_btn {
        flex-wrap: wrap;
    }

    .card .card-options .dropdown-toggle {
        margin-left: 15px;
    }

    .ticket_export {
        margin-left: 0px !important;
    }

    .mandatory {
        display: block;
    }

    .save_update_res {
        justify-content: space-between;
    }

    .save_update_res .form-group {
        margin-left: 40px;
    }

    .title_mt {
        display: block;
    }

    .title_mt .header-action {
        float: left;
        margin-top: 15px;
    }

    .userView .col-lg-5 {
        border: none;
    }

    .border_list_cash_count {
        border: none;
    }

    .cash_count_card button {
        width: 100%;
        margin-bottom: 10px;
    }

    .cash_count_card .card-footer {
        display: grid;
    }

    .common_print_pageg .title_print_btn {
        display: block;
    }

    .print_back {
        display: flex;
        margin-top: 15px;
    }

    .common_print {
        margin-left: 10px;
    }

    .assign {
        margin-top: 15px;
    }

    .price_inc_de {
        justify-content: start;
    }

    .qua_lable {
        margin-left: 0px;
    }

    .message_detail {
        border: none !important;
    }

    .set_permission_res {
        display: block;
    }

    .set_permission_input_res {
        margin-left: 25px;
    }

    .flg_col_res {
        padding-right: 7.5px;
    }

    .num_col_res {
        padding-left: 7.5px;
        margin: 20px 0;
    }

    .header-action .guest_checkinnow {
        padding: 10px 5px;
        font-size: 15px;
    }

    #statusChange .modal-body,
    #disableModal .modal-body {
        width: 410px;
    }
}




/* iPhone 12 pro ----------- */

@media only screen and (max-device-width: 390px) {
    .section-body {
        margin-left: 0px;
    }

    .modal.show .modal-dialog {
        max-width: 100% !important;
        margin-top: 70px;
    }

    .review_sec .top_title {
        margin-right: 30px !important;
    }

    #page_top .page-header .left .css-b62m3t-container {
        width: 345px;
    }

    .num_res {
        margin-top: 0px;
    }

    .view_btn_tic_res {
        margin-top: 10px;
        float: left;
    }

    .next_previous_btn {
        justify-content: space-between;
    }

    .breadcrump p {
        flex-wrap: wrap;
        padding-left: 20px;
    }

    .user_wise_nacat {
        width: 100%;
    }

    .Over_all_table_style {
        padding: 0px;
    }

    .send-review-bread {
        right: 20px;
    }

    .upcommcomp_btn button {
        width: 150px;
        margin-bottom: 10px;
    }

    .upcommcomp_btn {
        flex-wrap: wrap;
    }

    .card .card-options .dropdown-toggle {
        margin-left: 15px;
    }

    .ticket_export {
        margin-left: 0px !important;
    }

    .mandatory {
        display: block;
    }

    .save_update_res {
        justify-content: space-between;
    }

    .save_update_res .form-group {
        margin-left: 40px;
    }

    .title_mt {
        display: block;
    }

    .title_mt .header-action {
        float: left;
        margin-top: 15px;
    }

    .userView .col-lg-5 {
        border: none;
    }

    .border_list_cash_count {
        border: none;
    }

    .cash_count_card button {
        width: 100%;
        margin-bottom: 10px;
    }

    .cash_count_card .card-footer {
        display: grid;
    }

    .common_print_pageg .title_print_btn {
        display: block;
    }

    .print_back {
        display: flex;
        margin-top: 15px;
    }

    .common_print {
        margin-left: 10px;
    }

    .assign {
        margin-top: 15px;
    }

    .price_inc_de {
        justify-content: start;
    }

    .qua_lable {
        margin-left: 0px;
    }

    .message_detail {
        border: none !important;
    }

    .set_permission_res {
        display: block;
    }

    .set_permission_input_res {
        margin-left: 25px;
    }

    .flg_col_res {
        padding-right: 7.5px;
    }

    .num_col_res {
        padding-left: 7.5px;
        margin: 20px 0;
    }

    .header-action .guest_checkinnow {
        padding: 10px 5px;
        font-size: 15px;
    }


    #statusChange .modal-body,
    #disableModal .modal-body {
        width: 410px;
    }
}




/* iPhone 6, 7, 8 ----------- */

@media only screen and (max-device-width: 375px) {
    .section-body {
        margin-left: 0px;
    }

    #page_top .page-header .left .css-b62m3t-container {
        width: 345px;
    }

    .num_res {
        margin-top: 0px;
    }

    .view_btn_tic_res {
        margin-top: 10px;
        float: left;
    }

    .next_previous_btn {
        justify-content: space-between;
    }

    .breadcrump p {
        flex-wrap: wrap;
        padding-left: 20px;
    }

    .user_wise_nacat {
        width: 100%;
    }

    .Over_all_table_style {
        padding: 0px;
    }

    .send-review-bread {
        right: 20px;
    }

    .upcommcomp_btn button {
        width: 150px;
        margin-bottom: 10px;
    }

    .upcommcomp_btn {
        flex-wrap: wrap;
    }

    .card .card-options .dropdown-toggle {
        margin-left: 15px;
    }

    .ticket_export {
        margin-left: 0px !important;
    }

    .mandatory {
        display: block;
    }

    .save_update_res {
        justify-content: space-between;
    }

    .save_update_res .form-group {
        margin-left: 40px;
    }

    .title_mt {
        display: block;
    }

    .title_mt .header-action {
        float: left;
        margin-top: 15px;
    }

    .userView .col-lg-5 {
        border: none;
    }

    .border_list_cash_count {
        border: none;
    }

    .cash_count_card button {
        width: 100%;
        margin-bottom: 10px;
    }

    .cash_count_card .card-footer {
        display: grid;
    }

    .common_print_pageg .title_print_btn {
        display: block;
    }

    .print_back {
        display: flex;
        margin-top: 15px;
    }

    .common_print {
        margin-left: 10px;
    }

    .assign {
        margin-top: 15px;
    }

    .set_permission_res {
        display: block;
    }

    .set_permission_input_res {
        margin-left: 25px;
    }

    .flg_col_res {
        padding-right: 7.5px;
    }

    .num_col_res {
        padding-left: 7.5px;
        margin: 20px 0;
    }

    #statusChange .modal-body,
    #disableModal .modal-body {
        width: 410px;
    }
}




/* samsung galaxy  */

@media only screen and (max-device-width: 360px) {
    .review_sec {
        margin-left: 0px;
    }

    .num_res {
        margin-top: 0px;
    }

    .view_btn_tic_res {
        margin-top: 10px;
        float: left;
    }

    .next_previous_btn {
        justify-content: space-between;
    }

    .breadcrump p {
        flex-wrap: wrap;
        padding-left: 20px;
    }

    .user_wise_nacat {
        width: 100%;
    }

    .Over_all_table_style {
        padding: 0px;
    }

    .send-review-bread {
        right: 20px;
    }

    .upcommcomp_btn button {
        width: 150px;
        margin-bottom: 10px;
    }

    .upcommcomp_btn {
        flex-wrap: wrap;
    }

    .card .card-options .dropdown-toggle {
        margin-left: 15px;
    }

    .ticket_export {
        margin-left: 0px !important;
    }

    .mandatory {
        display: block;
    }

    .save_update_res {
        justify-content: space-between;
    }

    .save_update_res .form-group {
        margin-left: 40px;
    }

    .title_mt {
        display: block;
    }

    .title_mt .header-action {
        float: left;
        margin-top: 15px;
    }

    .userView .col-lg-5 {
        border: none;
    }

    .border_list_cash_count {
        border: none;
    }

    .cash_count_card button {
        width: 100%;
        margin-bottom: 10px;
    }

    .cash_count_card .card-footer {
        display: grid;
    }

    .assign {
        margin-top: 15px;
    }

    .price_inc_de {
        justify-content: start;
    }

    .qua_lable {
        margin-left: 0px;
    }

    .message_detail {
        border: none !important;
    }

    .set_permission_res {
        display: block;
    }

    .set_permission_input_res {
        margin-left: 25px;
    }

    .flg_col_res {
        padding-right: 7.5px;
    }

    .num_col_res {
        padding-left: 7.5px;
        margin: 20px 0;
    }

    #statusChange .modal-body,
    #disableModal .modal-body {
        width: 410px;
    }
}